import type { RouteRecordRaw } from 'vue-router';

function MemberPersonalInfoPageVue() {
  return import('@/components/member/MemberPersonalInfoPage.vue');
}

export const memberAccountRoutes: RouteRecordRaw[] = [
  {
    path: 'personal-info',
    name: 'member-personal-info',
    component: MemberPersonalInfoPageVue,
  },
];
