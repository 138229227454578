<script setup lang="ts">
import { Icon } from '@iconify/vue';
import { useQueryClient } from '@tanstack/vue-query';
import { clubSidebarItems, memberSidebarItems } from './constants';
import type { SidebarItem } from './models';
import { type User, UserType } from '@/data/users/models';

const queryClient = useQueryClient();

const currentUser: User | undefined = queryClient.getQueryData(['currentUser']);

let displayedItems: SidebarItem[] = [];

switch (currentUser?.user_type) {
  case UserType.MEMBER:
    displayedItems = memberSidebarItems;
    break;

  case UserType.CLUB:
    displayedItems = clubSidebarItems;
    break;
}
</script>

<template>
  <!-- Sidebar Toggle -->
  <!-- <div
    class="sticky top-0 inset-x-0 z-20 bg-[#00195e] border-y px-4 sm:px-6 md:px-8 lg:hidden dark:bg-gray-800 dark:border-gray-700"
  >
    <div class="flex items-center py-4">
      <button
        type="button"
        class="text-white hover:bg-blue-600"
        data-hs-overlay="#application-sidebar"
        aria-controls="application-sidebar"
        aria-label="Toggle navigation"
      >
        <span class="sr-only">Toggle Navigation</span>
        <Icon class="icon-size-sm" icon="ci:hamburger-lg" />
      </button>
    </div>
  </div> -->
  <!-- End Sidebar Toggle -->

  <!-- Sidebar -->
  <div id="application-sidebar" class="">
    <nav class="py-6 w-full flex flex-col flex-wrap">
      <ul class="space-y-px">
        <li v-for="item of displayedItems" :key="item.name">
          <router-link
            v-if="item.route"
            class="sidebar-item"
            :to="{ name: item.route }"
            active-class="sidebar-item-active"
          >
            <Icon class="icon-size-lg sidebar-item-icon" :icon="item.icon" />
            <span>{{ item.name }}</span>
          </router-link>

          <a v-else-if="item.href" :href="item.href" class="sidebar-item">
            <Icon class="icon-size-lg sidebar-item-icon" :icon="item.icon" />
            <span>{{ item.name }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <!-- End Sidebar -->
</template>

<style scoped lang="scss">
.sidebar-item {
  @apply w-full flex items-center gap-x-3.5 py-3 px-8 text-base font-semibold text-white hover:bg-primary-purple-600 border-l-[6px] border-l-transparent transition-all duration-200 ease-in-out;
}

.sidebar-item-icon {
  @apply text-primary-cyan-400;
}

.sidebar-item-active {
  @apply bg-primary-purple-600 border-primary-cyan-400;
}
</style>
