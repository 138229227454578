import type { SidebarItem } from '../models';

export const clubSidebarItems: SidebarItem[] = [
  {
    name: 'Trials',
    icon: 'mdi:form-outline',
    route: 'club-trials-list',
  },
  {
    name: 'Club Info',
    icon: 'tabler:user',
    route: 'club-info',
  },
  {
    name: 'Trial Secretaries',
    icon: 'tabler:user', // TODO: Update icon
    route: 'club-trial-secretaries-list',
  },
];
