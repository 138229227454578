import ky from 'ky'; // library to do Ajax request
import router from '@/router';

export const http = ky.extend({
  prefixUrl: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  retry: 0,

  hooks: {
    beforeRequest: [
      (request) => {
        const token = localStorage.getItem('auth-token');
        if (token) {
          request.headers.set('Authorization', `Token ${token}`);
        }
      },
    ],

    afterResponse: [
      (_request, _options, response) => {
        if (response.status === 401) {
          localStorage.removeItem('auth-token');
          router.push({ name: 'login' });
        }
      },
    ],
  },
});
