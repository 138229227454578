import { http } from '@/core';
import type {
  LoginForm,
  Member,
  MemberInfoFormPayload,
  RegisterMemberFormPayload,
  User,
  UserAccountFormPayload,
  UserSecurityFormPayload,
} from '@/data/users/models';

// Login member
export async function loginMember(payload: LoginForm) {
  return await http
    .post('_allauth/app/v1/auth/login', { json: payload })
    .json<any>();
}

// Get club token for login
export async function getClubTokenForLogin(payload: LoginForm) {
  return await http.post(import.meta.env.VITE_WP_AUTH_TOKEN_URL, { json: payload, prefixUrl: undefined }).json<any>();
}

// Login club from token
export async function loginClubFromToken(token: string) {
  return await http.post('_allauth/app/v1/auth/provider/token', {
    json: {
      provider: 'authwordpress',
      process: 'login',
      token: {
        client_id: '',
        access_token: token,
      },
    },
  }).json<any>();
}

// Logout user
export async function logoutUser() {
  return await http.delete('_allauth/app/v1/auth/session').json<void>();
}

// Register member
export async function registerMember(payload: RegisterMemberFormPayload) {
  return await http
    .post('_allauth/app/v1/auth/signup', { json: payload })
    .json<void>();
}

// Get current user details
export async function getCurrentUser() {
  return await http.get('me/').json<User>();
}

// Set email
export async function setEmail(payload: UserAccountFormPayload) {
  return await http
    .post('auth/users/set_email/', { json: payload })
    .json<void>();
}

// Set password
export async function setPassword(payload: UserSecurityFormPayload) {
  return await http
    .post('auth/users/set_password/', { json: payload })
    .json<void>();
}

// Update member info
export async function updateMemberInfo(payload: MemberInfoFormPayload) {
  return await http.patch('me/member/', { json: payload }).json<Member>();
}
