import type { RouteRecordRaw } from 'vue-router';
import { UserType } from '@/data/users/models';

const LoginPageVue = () => import('@/components/auth/LoginPage.vue');
function RegisterMemberPageVue() {
  return import('@/components/auth/RegisterMemberPage.vue');
}

export const authRoutes: RouteRecordRaw[] = [
  {
    path: '/login/member',
    name: 'login-member',
    component: LoginPageVue,
    meta: { requiresAuth: false, hideIfAuth: true },
    props: { userType: UserType.MEMBER },
  },
  {
    path: '/login/club',
    name: 'login-club',
    component: LoginPageVue,
    meta: { requiresAuth: false, hideIfAuth: true },
    props: { userType: UserType.CLUB },
  },
  {
    path: '/register/member',
    name: 'register-member',
    component: RegisterMemberPageVue,
    meta: { requiresAuth: false },
  },
];
