import type { RouteRecordRaw } from 'vue-router';

function MemberDogFormPage() {
  return import('@/components/member/MemberDogFormPage.vue');
}
function MemberDogListPageVue() {
  return import('@/components/member/MemberDogListPage.vue');
}

export const memberDogsRoutes: RouteRecordRaw[] = [
  {
    path: 'dogs',
    name: 'member-dogs-list',
    component: MemberDogListPageVue,
  },
  {
    path: 'dogs/create',
    name: 'member-dogs-create',
    component: MemberDogFormPage,
  },
  {
    path: 'dogs/:id/edit',
    name: 'member-dogs-edit',
    component: MemberDogFormPage,
    props: route => ({ id: Number(route.params.id) }),
  },
];
