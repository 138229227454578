import type { Member } from './member';
import type { Club } from './club';
import type { Maybe } from '@/utilities';

export enum UserType {
  MEMBER = 'member',
  CLUB = 'club',
}

export interface User {
  id: number;
  email: string;
  user_type: UserType;
  member: Maybe<Member>;
  club: Maybe<Club>;
  display_name: string;
  display_initials: string;
}
