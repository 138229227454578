<script setup lang="ts">
import { computed } from 'vue';
import { Icon } from '@iconify/vue';
import type { DropdownOption } from './models';

const props = defineProps({
  context: { type: Object, default: () => {} },
});

const options = computed(() => props.context.dropdownOptions);

const selectedOption = computed(() =>
  options.value?.find((option: any) => option.value === props.context._value),
);

function handleInput(selectedOption: DropdownOption) {
  props.context.node.input(selectedOption.value);
}
</script>

<template>
  <PrimeSelect
    :model-value="selectedOption"
    :options="options"
    option-label="label"
    class="w-full"
    @update:model-value="handleInput($event)"
  >
    <template #dropdownicon>
      <Icon
        icon="mage:chevron-down-square-fill"
        class="icon-size-md flex-shrink-0 text-neutral-300"
      />
    </template>

    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex items-center gap-x-2">
        <Icon
          v-if="slotProps.value.leftIconName"
          class="flex-shrink-0 icon-size-md"
          :icon="slotProps.value.leftIconName"
          :class="slotProps.value.leftIconColorClass"
        />
        <div>{{ slotProps.value.label }}</div>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>

    <template #option="slotProps">
      <div class="flex items-center gap-x-2">
        <Icon
          v-if="slotProps.option.leftIconName"
          class="flex-shrink-0 icon-size-md"
          :icon="slotProps.option.leftIconName"
          :class="slotProps.option.leftIconColorClass"
        />
        <div>{{ slotProps.option.label }}</div>
      </div>
    </template>
  </PrimeSelect>
</template>

<style scoped lang="scss">
.p-select {
  @apply shadow-none border-[1.5px] border-neutral-300 hover:border-neutral-300 rounded-xl py-2 px-3 w-full text-lg transition-colors duration-200 ease-in-out;
}

.p-select:not(.p-disabled):hover {
  @apply border-neutral-300;
}

.p-select:not(.p-disabled).p-focus {
  @apply border-primary-purple-400 #{!important};
}

:deep(.p-select-label) {
  @apply p-0 text-primary-purple-900;
}

:global(.p-select-overlay) {
  @apply shadow-none rounded-xl border-none bg-neutral-100 mt-1 #{!important};
}

:global(.p-select-overlay .p-select-option) {
  @apply text-primary-purple-900 font-medium #{!important};
}

:global(.p-select-overlay .p-select-option.p-focus) {
  @apply bg-primary-purple-200 #{!important};
}

:global(.p-select-overlay .p-select-option.p-select-option-selected) {
  @apply bg-primary-purple-700 text-white #{!important};
}

:global(.p-select-list) {
  @apply px-0 #{!important};
}

:global(.p-select-overlay .p-select-option) {
  @apply text-primary-purple-900 font-medium #{!important};
}
</style>
