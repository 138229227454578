import type { RouteRecordRaw } from 'vue-router';

function MemberTrialListPageVue() {
  return import('@/components/member/MemberTrialListPage.vue');
}

function MemberEnteredTrialsListPageVue() {
  return import('@/components/member/MemberEnteredTrialsListPage.vue');
}

export const memberTrialsRoutes: RouteRecordRaw[] = [
  {
    path: 'trials',
    name: 'member-trials-list',
    component: MemberTrialListPageVue,
  },
  {
    path: 'trials/entered',
    name: 'member-entered-trials-list',
    component: MemberEnteredTrialsListPageVue,
  },
];
