import type { RouteRecordRaw } from 'vue-router';

function ClubTrialSecretaryFormPage() {
  return import('@/components/club/ClubTrialSecretaryFormPage.vue');
}
function ClubTrialSecretaryListPage() {
  return import('@/components/club/ClubTrialSecretaryListPage.vue');
}

export const clubTrialSecretariesRoutes: RouteRecordRaw[] = [
  {
    path: 'trial-secretaries',
    name: 'club-trial-secretaries-list',
    component: ClubTrialSecretaryListPage,
  },
  {
    path: 'trial-secretaries/create',
    name: 'club-trial-secretaries-create',
    component: ClubTrialSecretaryFormPage,
  },
  {
    path: 'trial-secretaries/:id/edit',
    name: 'club-trial-secretaries-edit',
    component: ClubTrialSecretaryFormPage,
    props: true,
  },
];
