<script setup lang="ts">
enum Env {
  Local = 'local',
  Dev = 'dev',
  Staging = 'staging',
  Prod = 'prod',
}

const envColorMap = new Map<Env, string>([
  [Env.Local, 'bg-gray-500'],
  [Env.Dev, 'bg-red-800'],
  [Env.Staging, 'bg-yellow-700'],
  [Env.Prod, 'bg-primary-purple-600'],
]);

const badgeBackgroundClass = envColorMap.get(import.meta.env.VITE_ENV) ?? '';
const badgeText = ['Alpha', import.meta.env.VITE_VERSION]
  .filter(Boolean)
  .join(' ');
</script>

<template>
  <div
    class="flex items-center py-0.5 px-2.5 rounded-md text-xs font-medium text-white mr-6"
    :class="badgeBackgroundClass"
  >
    {{ badgeText }}
  </div>
</template>
