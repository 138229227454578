import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createMultiStepPlugin } from '@formkit/addons';
import '@formkit/addons/css/multistep';

import { createInput, defaultConfig, plugin } from '@formkit/vue';
import { generateClasses } from '@formkit/themes';

import './assets/main.scss';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { createProPlugin, inputs } from '@formkit/pro';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Select from 'primevue/select';

import { createNotivue } from 'notivue';
import * as Sentry from '@sentry/vue';

import 'notivue/notification.css'; // Only needed if using built-in notifications
import 'notivue/animations.css'; // Only needed if using built-in animations
import DatePicker from 'primevue/datepicker';
import router from './router';
import App from './App.vue';
import OEDatePicker from './components/formkit/OEDatePicker.vue';
import OEDropdown from '@/components/formkit/OEDropdown.vue';

const app = createApp(App);

app.use(VueQueryPlugin);
app.use(createPinia());

// Adapted from https://formkit.link/2501ecc507c5c87ff6f9f5996110c9c5
// See https://formkit.com/essentials/examples#plugins
const legends = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist'];
function addAsteriskPlugin(node: any) {
  if (
    ['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(
      node.props.type,
    )
  ) {
    return;
  }

  node.on('created', () => {
    const legendOrLabel = legends.includes(
      `${node.props.type}${node.props.options ? '_multi' : ''}`,
    )
      ? 'legend'
      : 'label';

    if (node.props.definition.schemaMemoKey) {
      node.props.definition.schemaMemoKey += `${node.props.options ? '_multi' : ''}_add_asterisk`;
    }

    const schemaFn = node.props.definition.schema;
    node.props.definition.schema = (sectionsSchema: any = {}) => {
      sectionsSchema[legendOrLabel] = {
        children: [
          '$label',
          {
            $el: 'span',
            if: '$state.required',
            attrs: {
              class: 'form-input-asterisk',
            },
          },
        ],
      };

      return schemaFn(sectionsSchema);
    };
  });
}

const pro = createProPlugin(import.meta.env.VITE_FORMKIT_PRO_KEY, inputs);

app.use(
  plugin,
  defaultConfig({
    inputs: {
      oeDropdown: createInput(OEDropdown, {
        props: ['dropdownOptions'],
      }),
      oeDatePicker: createInput(OEDatePicker, { props: ['mode'] }),
    },
    config: {
      classes: generateClasses({
        'global': {
          message: 'text-red-700 text-sm mt-0.5',
          help: 'text-slate-700 text-sm mt-0.5',
        },

        'form': {
          messages:
            'mt-0 mb-4 border border-red-100 bg-red-50 rounded-lg px-2 py-1',
          form: 'border-none rounded-2xl bg-white overflow-hidden p-8',
        },

        'multi-step': {
          wrapper: '!max-w-full !shadow-none',
          steps: '!py-4 !px-0 !bg-transparent !border-none',
        },

        'family:text': {
          input:
            'border-[1.5px] border-neutral-300 rounded-xl py-2 px-3 w-full text-primary-purple-900 text-lg focus:outline-none focus:border-primary-purple-400 transition-colors duration-200 ease-in-out',
          label: 'form-input-label',
        },

        'select': {
          label: 'form-input-label',
        },

        'radio': {
          legend: 'form-input-label',
          wrapper: 'flex items-center gap-x-1 cursor-pointer',
          input: 'appearance-none w-0 h-0 overflow-hidden',
          label: '-mt-1.5 text-primary-purple-900 font-medium',
        },

        'checkbox': {
          wrapper: 'flex items-center gap-x-2',
          input: 'appearance-none w-0 h-0 overflow-hidden',
          label: 'text-primary-purple-900 font-medium cursor-pointer',
          inner:
            'relative w-4 h-4 border-[1.5px] border-primary-purple-800 rounded-sm cursor-pointer',
          decorator: 'block absolute top-0 w-full h-full',
        },

        'submit': {
          input:
            'flex items-center justify-center gap-x-1 bg-primary-cyan-400 py-1.5 w-40 px-auto text-sm font-semibold text-primary-purple-900 rounded-xl cursor-pointer hover:bg-primary-cyan-500 transition-colors duration-200 ease-in-out',
        },

        'oeDropdown': {
          label: 'form-input-label',
        },

        'oeDatePicker': {
          label: 'form-input-label',
        },
      }),
    },
    messages: {
      en: {
        validation: {
          required() {
            return '';
          },
        },
      },
    },
    plugins: [pro, addAsteriskPlugin, createMultiStepPlugin()],
  }),
);
app.use(router);

app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: '',
    },
  },
});

app.component('PrimeSelect', Select);
app.component('PrimeDatePicker', DatePicker);

const notivue = createNotivue(/* options */);
app.use(notivue);

if (import.meta.env.VITE_ENV !== 'local') {
  Sentry.init({
    app,
    dsn: 'https://13b04a02142a8f62d7821424981e56a0@o4507904286195712.ingest.us.sentry.io/4507904294191104',
    environment: import.meta.env.VITE_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api-dev.entries.agilityunscrambled.com/,
      /^https:\/\/api-staging.entries.agilityunscrambled.com/,
      /^https:\/\/api.entries.agilityunscrambled.com/,
    ],
  });
}

app.mount('#app');
