<script setup lang="ts">
import { Icon } from '@iconify/vue';
import { UserType } from '@/data/users/models';
import { useCurrentUserQuery, useLogoutMutation } from '@/services/users';

const { data: currentUser } = useCurrentUserQuery();

const { mutate: logoutUser } = useLogoutMutation();
</script>

<template>
  <div class="mx-4 mb-10 bg-primary-purple-600 rounded-xl">
    <div class="flex items-center gap-x-2 px-2 pt-5 pb-6">
      <Icon
        icon="clarity:id-badge-solid"
        class="text-primary-cyan-400 flex-shrink-0 w-12 h-12"
      />
      <div class="space-y-1">
        <div class="text-white font-semibold">
          {{ currentUser?.display_name }}
        </div>
        <div class="flex items-center gap-x-4">
          <div
            class="flex items-center py-0.5 px-2.5 rounded-md text-xs font-medium text-white bg-cyan-600 w-max"
          >
            {{ currentUser?.user_type === UserType.MEMBER ? 'Member' : 'Club' }}
          </div>
          <Icon
            icon="mdi:sign-out"
            class="text-neutral-100 w-5 h-5 cursor-pointer"
            @click="logoutUser()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
