import type { RouteRecordRaw } from 'vue-router';

function MemberTrialEntryFormPageVue() {
  return import('@/components/member/MemberTrialEntryFormPage.vue');
}

export const memberEntriesRoutes: RouteRecordRaw[] = [
  {
    path: 'trials/:trialId/entries/create',
    name: 'member-trials-entry-create',
    component: MemberTrialEntryFormPageVue,
    props: route => ({ trialId: Number(route.params.trialId) }),
  },
  {
    path: 'trials/:trialId/entries/:id/edit',
    name: 'member-trials-entry-edit',
    component: MemberTrialEntryFormPageVue,
    props: route => ({
      trialId: Number(route.params.trialId),
      id: Number(route.params.id),
    }),
  },
];
