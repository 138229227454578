import type { RouteRecordRaw } from 'vue-router';

const ClubInfoPage = () => import('@/components/club/ClubInfoPage.vue');

export const clubInfoRoutes: RouteRecordRaw[] = [
  {
    path: 'club-info',
    name: 'club-info',
    component: ClubInfoPage,
  },
];
